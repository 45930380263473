import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

class Skills extends Component {
    render() {
        return (
            <div className="skills-section">
                <h2>Skills</h2>
                <div className="skills-list">
                    <Row className="justify-content-center">
                        <Col lg={3} md={4}>
                            <span className="skill my-2">PHP</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">React</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">Vue.js</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">JavaScript</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">Python</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">LUA</span>
                        </Col>
                        <Col lg={3} md={4}>
                            <span className="skill my-2">Blender</span>
                        </Col>
                    </Row>
                </div>
            </div>

        );
    }
}

export default Skills;